import React from "react";

import "./style.css";

import Section from "./Section";
import Overview from "./Section/Overview";
import PropertyGallery from "./Section/PropertyGallery";
import Help from "./Section/Help";

const Dash = (props) => {
  return (
    <div className="home-dash-wrap">
      <Section title={"overview"}>
        <Overview />
      </Section>
      <Section title={"Properties"}>
        <PropertyGallery />
      </Section>
      <div className="dash-div-lin" />
      <Section title={"Getting Started"}>
        <Help />
      </Section>
    </div>
  );
};

export default Dash;
