import { SET_SIDENAV } from "../types";

const initialState = {
  sideNav: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_SIDENAV":
      return {
        ...state,
        sideNav: !state.sideNav,
      };
    default:
      return state;
  }
}
