import React, { useState } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { toggleSideNavAction } from "../../redux/actions/uiActions";

import "./style.css";

import LogoWrap from "./LogoWrap";
import DashWrap from "./DashWrap";
import AccntWrap from "./AccntWrap";
import CollapWrap from "./CollapWrap";
import CollectWrap from "./CollectWrap";

const Navigation = (props) => {
  const savedState =
    JSON.parse(localStorage.getItem(`nav-min`)) === true ? true : false;
  const [min, setMin] = useState(savedState);

  const sideNav = useSelector((state) => state.Ui.sideNav);
  const dispatch = useDispatch();
  const toggleSideNav = () => dispatch(toggleSideNavAction());

  return (
    <div
      className="navigation-wraper"
      style={{ width: !min ? "256px" : "68px" }}
    >
      <div
        className={`navigation-mobile-backdrop rev-fade ${
          !sideNav ? null : "navigation-mobile-backdrop-active"
        }`}
        onClick={toggleSideNav}
      />
      <div
        className={`navigation-container ${
          !min ? "navigation-container-max" : "navigation-container-min"
        } ${
          !sideNav ? null : "navigation-container-active"
        } foreground-wrap flex-col flex-just-out global-trans`}
      >
        <LogoWrap min={min} setNav={toggleSideNav} />
        <DashWrap min={min} />
        <CollectWrap min={min} collections={props.collections} />
        <AccntWrap min={min} />
        <CollapWrap
          min={min}
          setMin={() => {
            localStorage.setItem(`nav-min`, !min);
            setMin(!min);
          }}
        />
      </div>
    </div>
  );
};

export default Navigation;
