import React from "react";

import "./style.css";
import Tab from "./Tab";

const Tabs = (props) => {
  const buildRoute = (name) => {
    return name.replace(/\s/g, "-").toLowerCase();
  };

  let toRender = props.display ? null : (
    <div className="tabs-wrap flex-align-cent">
      {props.tabs.map((tab, i) => {
        let route = i > 0 ? "/" + buildRoute(tab.name) : "";
        return (
          <Tab
            key={Math.random()}
            name={tab.name}
            route={route}
            root={props.root}
          />
        );
      })}
    </div>
  );
  return toRender;
};

export default Tabs;
