import { FAVORITE_PROPRTY } from "../types";

const initialState = {
  properties: [
    {
      id: "123",
      img:
        "https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1026205392%2F0x0.jpg",
      address: "16 Black Creek Lane",
      state: "St. Louis",
    },
    {
      id: "abc",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRyCM674IzA4ZI8T5MYYVJdIZWXPSVcprcTTw&usqp=CAU",
      address: "17 Black Creek Lane",
      state: "St. Louis",
    },
    {
      id: "a12s3",
      img:
        "https://vignette.wikia.nocookie.net/memorydelta/images/0/06/Typhuss%27s_house_on_Earth.jpg/revision/latest?cb=20170220063537",
      address: "18 Black Creek Lane",
      state: "St. Louis",
    },
  ],
  watchlist: ["abc"],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FAVORITE_PROPRTY":
      return {
        ...state,
        watchlist: addOrRemove(state.watchlist, action.payload),
      };
    default:
      return state;
  }
}

function addOrRemove(array, value) {
  var index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}
