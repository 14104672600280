import React from "react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { toggleSideNavAction } from "../../redux/actions/uiActions";

import "./style.css";

//Components
import Profile from "./Profile";
import ActiveProp from "./ActiveProp";

//icons
import MobileMenu from "../../media/svgs/MobileMenu";

const Header = (props) => {
  const sideNav = useSelector((state) => state.Ui.sideNav);
  const dispatch = useDispatch();
  const toggleSideNav = () => dispatch(toggleSideNavAction());

  return (
    <div
      className={`header-wrap flex-align-cent flex-just-out ${props.className}`}
    >
      <div className=" flex-align-cent" style={{ height: "100%" }}>
        <button
          className="button-icon-button rev-rit mobile-only mobile-menu-button"
          onClick={toggleSideNav}
        >
          <MobileMenu />
        </button>
        <ActiveProp />
      </div>
      <Profile />
    </div>
  );
};

export default Header;
