import React from "react";

import "./style.css";

const Sec = (props) => {
  return (
    <div className="dash-Tile-wrap">
      <div className="dash-Tile foreground-wrap global-app-wrap">Tile 1</div>
      <div className="dash-Tile foreground-wrap global-app-wrap">Tile 2 </div>
      <div className="dash-Tile-lg foreground-wrap global-app-wrap">Tile 3</div>
    </div>
  );
};

export default Sec;
