import React, { useState, useEffect, useRef } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import "./style.css";

//Components
import Tile from "./Tile";

//icon
import MenuDwn from "../../../media/svgs/MenuDwn";

//images
import placeholder from "../../../media/images/placeholderProperty.png";

const Switch = (props) => {
  //refs
  const wrapperRef = useRef(null);

  //local and state vars
  const propList = useSelector((state) => state.User.properties);
  const localProperty = localStorage.getItem("activeProp"); //move this to app.js as it effects everything
  const histProp =
    !propList && !localProperty
      ? null
      : !localProperty
      ? propList[0]
      : propList.find((property) => property.id === localProperty);
  const [activeProp, setActiveProp] = useState(histProp); //change redux state
  const [open, setOpen] = useState(false);

  //click habdlers
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  let truncate = 16;
  let propOpts = !activeProp
    ? propList
    : propList.filter((property) => {
        return property.id !== activeProp.id;
      });

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setOpen(false);
    }
  };
  const setNewProperty = (property) => {
    setOpen(false);
    setActiveProp(property);
    localStorage.setItem("activeProp", property.id);
  };

  return (
    <div className="header-prop-cont">
      <div
        ref={wrapperRef}
        className={`header-prop-wrap global-trans ${
          !open ? null : "header-prop-wrap-active"
        }`}
        style={{
          height: !open
            ? "48px"
            : `${activeProp ? propOpts.length * 48 + 150 : 133}px`,
        }}
      >
        <div className="header-prop-act" onClick={() => setOpen(!open)}>
          <Tile open={open} property={activeProp} active={true} />
        </div>
        {!open ? null : (
          <div className="rev-down">
            {propOpts.length > 1 ? (
              <div className="header-prop-opt">
                {propOpts.map((property) => (
                  <Tile
                    key={property.id}
                    open={open}
                    property={property}
                    onClick={() => setNewProperty(property)}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="header-prop-foot">
              <div className="header-prop-foot-butt global-trans flex-align-cent secondary-txt txt">
                View all properties
              </div>
              <div className="header-prop-foot-butt global-trans flex-align-cent secondary-txt txt">
                Add a property
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Switch;
