import React from "react";
import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";

import "./style.css";
import AddProp from "../../../../media/svgs/AddProp";

const Sec = (props) => {
  const propList = useSelector((state) => state.User.properties);

  return (
    <div className="dash-property-gallery-wrap">
      {propList.map((proprty) => (
        <div
          key={"dashboard-prop-" + proprty.id}
          style={{
            background: `url(${proprty.img})`,
            backgroundSize: `100% 100%`,
          }}
          className="dash-prop-tile foreground-wrap global-app-wrap"
        >
          {proprty.address}
        </div>
      ))}
      <Link
        to="/flippability-search"
        className="dash-prop-tile-add flex-align-cent flex-just-cent flex-col foreground-wrap global-app-wrap"
      >
        <AddProp />
        Add Property
      </Link>
    </div>
  );
};

export default Sec;
