import React from "react";
import { NavLink } from "react-router-dom";

import "./style.css";

const Navigation = (props) => {
  return (
    <div
      style={{ width: "100%" }}
      className={`nav-dash-wrap  flex-just-out  ${
        !props.min
          ? "flex-align-out"
          : "nav-dash-wrap-min flex-col flex-align-cent"
      }`}
    >
      <NavLink to="/" exact activeClassName="nav-section-butt-active">
        {!props.min ? (
          <button className="flex-align-cent button-nav ">
            <svg viewBox="0 0 24 24">
              <path d="M12 16C13.66 16 15 14.66 15 13C15 11.88 14.39 10.9 13.5 10.39L3.79 4.77L9.32 14.35C9.82 15.33 10.83 16 12 16M12 3C10.19 3 8.5 3.5 7.03 4.32L9.13 5.53C10 5.19 11 5 12 5C16.42 5 20 8.58 20 13C20 15.21 19.11 17.21 17.66 18.65H17.65C17.26 19.04 17.26 19.67 17.65 20.06C18.04 20.45 18.68 20.45 19.07 20.07C20.88 18.26 22 15.76 22 13C22 7.5 17.5 3 12 3M2 13C2 15.76 3.12 18.26 4.93 20.07C5.32 20.45 5.95 20.45 6.34 20.06C6.73 19.67 6.73 19.04 6.34 18.65C4.89 17.2 4 15.21 4 13C4 12 4.19 11 4.54 10.1L3.33 8C2.5 9.5 2 11.18 2 13Z" />
            </svg>
            Dashboard
          </button>
        ) : (
          <button className="button-icon">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 16C13.66 16 15 14.66 15 13C15 11.88 14.39 10.9 13.5 10.39L3.79 4.77L9.32 14.35C9.82 15.33 10.83 16 12 16M12 3C10.19 3 8.5 3.5 7.03 4.32L9.13 5.53C10 5.19 11 5 12 5C16.42 5 20 8.58 20 13C20 15.21 19.11 17.21 17.66 18.65H17.65C17.26 19.04 17.26 19.67 17.65 20.06C18.04 20.45 18.68 20.45 19.07 20.07C20.88 18.26 22 15.76 22 13C22 7.5 17.5 3 12 3M2 13C2 15.76 3.12 18.26 4.93 20.07C5.32 20.45 5.95 20.45 6.34 20.06C6.73 19.67 6.73 19.04 6.34 18.65C4.89 17.2 4 15.21 4 13C4 12 4.19 11 4.54 10.1L3.33 8C2.5 9.5 2 11.18 2 13Z" />
            </svg>
          </button>
        )}
      </NavLink>
      <div className="flex-align-cent">
        {!props.min ? <div className="nac-dash-lin line-vert" /> : null}
        <NavLink to="/settings" exact activeClassName="nav-section-butt-active">
          <button className="button-icon">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.9 18.45C17.25 18.45 18.35 17.35 18.35 16C18.35 14.65 17.25 13.55 15.9 13.55C14.54 13.55 13.45 14.65 13.45 16C13.45 17.35 14.54 18.45 15.9 18.45ZM21.1 16.68L22.58 17.84C22.71 17.95 22.75 18.13 22.66 18.29L21.26 20.71C21.17 20.86 21 20.92 20.83 20.86L19.09 20.16C18.73 20.44 18.33 20.67 17.91 20.85L17.64 22.7C17.62 22.87 17.47 23 17.3 23H14.5C14.32 23 14.18 22.87 14.15 22.7L13.89 20.85C13.46 20.67 13.07 20.44 12.71 20.16L10.96 20.86C10.81 20.92 10.62 20.86 10.54 20.71L9.14003 18.29C9.05003 18.13 9.09003 17.95 9.22003 17.84L10.7 16.68L10.65 16L10.7 15.31L9.22003 14.16C9.09003 14.05 9.05003 13.86 9.14003 13.71L10.54 11.29C10.62 11.13 10.81 11.07 10.96 11.13L12.71 11.84C13.07 11.56 13.46 11.32 13.89 11.15L14.15 9.29C14.18 9.13 14.32 9 14.5 9H17.3C17.47 9 17.62 9.13 17.64 9.29L17.91 11.15C18.33 11.32 18.73 11.56 19.09 11.84L20.83 11.13C21 11.07 21.17 11.13 21.26 11.29L22.66 13.71C22.75 13.86 22.71 14.05 22.58 14.16L21.1 15.31L21.15 16L21.1 16.68ZM6.69003 8.07C7.56003 8.07 8.26003 7.37 8.26003 6.5C8.26003 5.63 7.56003 4.92 6.69003 4.92C6.27098 4.92 5.8691 5.08646 5.5728 5.38277C5.27649 5.67908 5.11003 6.08096 5.11003 6.5C5.11003 7.37 5.82003 8.07 6.69003 8.07ZM10.03 6.94L11 7.68C11.07 7.75 11.09 7.87 11.03 7.97L10.13 9.53C10.08 9.63 9.96002 9.67 9.86002 9.63L8.74003 9.18L8.00003 9.62L7.81003 10.81C7.79003 10.92 7.70003 11 7.59003 11H5.79003C5.67003 11 5.58003 10.92 5.56003 10.81L5.40003 9.62L4.64003 9.18L3.50003 9.63C3.41003 9.67 3.30003 9.63 3.24003 9.53L2.34003 7.97C2.28003 7.87 2.31003 7.75 2.39003 7.68L3.34003 6.94L3.31003 6.5L3.34003 6.06L2.39003 5.32C2.31003 5.25 2.28003 5.13 2.34003 5.03L3.24003 3.47C3.30003 3.37 3.41003 3.33 3.50003 3.37L4.63003 3.82L5.40003 3.38L5.56003 2.19C5.58003 2.08 5.67003 2 5.79003 2H7.59003C7.70003 2 7.79003 2.08 7.81003 2.19L8.00003 3.38L8.74003 3.82L9.86002 3.37C9.96002 3.33 10.08 3.37 10.13 3.47L11.03 5.03C11.09 5.13 11.07 5.25 11 5.32L10.03 6.06L10.06 6.5L10.03 6.94Z" />
            </svg>
          </button>{" "}
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
