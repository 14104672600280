import React from "react";

import "./style.css";

//COMPS
import Dashboard from "../../comps/Dashboard";

const Home = (props) => {
  return (
    <div className="app-home-wrap flex-align-cent flex-col">
      <Dashboard />
    </div>
  );
};

export default Home;
