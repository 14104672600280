import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import "./style.css";

//components
import Switch from "../../../Switch";
import MenuItem from "./MenuItem";

//icons
import Forum from "../../../../media/svgs/Forum";
import Support from "../../../../media/svgs/Support";
import Close from "../../../../media/svgs/Close";

const Profile = (props) => {
  const currentTheme =
    JSON.parse(localStorage.getItem("theme")) === false ? false : true;
  const wrapperRef = useRef(null);
  const [toggle, setToggle] = useState(currentTheme);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const themeToggle = (e) => {
    setToggle(!toggle);
    document.documentElement.setAttribute(
      "data-theme",
      !toggle ? "dark" : "light"
    );
    localStorage.setItem("theme", !toggle);
  };
  const handleClickOutside = (e) => {
    //gets triggered on themeToggle becasue funciton is altering components outside the scope of the wrapperRef.current div
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      props.setOpen(false);
    }
  };
  const openInNewTab = (url) => {
    console.log(url);
    var win = window.open(url, "_blank");
    win.focus();
  };

  const menuOptions = [
    {
      txt: "Go to forum",
      media: <Forum />,
      func: (e) => openInNewTab("https://us-flipit.com/support"),
    },
    {
      txt: "Suppot",
      media: <Support />,
      func: (e) => openInNewTab("https://us-flipit.com/forum"),
    },
    {
      txt: "Dark mode",
      media: (
        <Switch checked={toggle} className="header-profile-themToggle-adj" />
      ),
      func: (e) => themeToggle(e),
    },
  ];

  return (
    <div
      ref={wrapperRef}
      className="header-profile-menu-wrap flex-col rev-down"
    >
      <section className="flex-align-cent flex-col ">
        <div className="header-profile-menu-pic-sec-wrap">
          <div className="header-profile-menu-pic-wrap">
            <img src={props.proPic} alt="Flipit Large Profile Pic" />
          </div>
          <div className="header-profile-menu-badge-wrap flex-align-cent  flex-just-cent ">
            <div className="header-profile-menu-badge flex-align-cent  flex-just-cent ">
              <svg viewBox="0 0 24 24">
                <path d="M9.23,17.59V23.12H6.88V6.72C6.88,5.27 7.31,4.13 8.16,3.28C9,2.43 10.17,2 11.61,2C13,2 14.07,2.34 14.87,3C15.66,3.68 16.05,4.62 16.05,5.81C16.05,6.63 15.79,7.4 15.27,8.11C14.75,8.82 14.08,9.31 13.25,9.58V9.62C14.5,9.82 15.47,10.27 16.13,11C16.79,11.71 17.12,12.62 17.12,13.74C17.12,15.06 16.66,16.14 15.75,16.97C14.83,17.8 13.63,18.21 12.13,18.21C11.07,18.21 10.1,18 9.23,17.59M10.72,10.75V8.83C11.59,8.72 12.3,8.4 12.87,7.86C13.43,7.31 13.71,6.7 13.71,6C13.71,4.62 13,3.92 11.6,3.92C10.84,3.92 10.25,4.16 9.84,4.65C9.43,5.14 9.23,5.82 9.23,6.71V15.5C10.14,16.03 11.03,16.29 11.89,16.29C12.73,16.29 13.39,16.07 13.86,15.64C14.33,15.2 14.56,14.58 14.56,13.79C14.56,12 13.28,11 10.72,10.75Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="txt primary-txt">username</div>
        <div className="sub-txt secondary-txt" style={{ marginBottom: "8px" }}>
          email@email.com
        </div>
        <button className="sub-txt button-line-theme  header-profile-menu-button">
          Manage profile
        </button>
      </section>
      <section className="flex-align-cent flex-col ">
        {menuOptions.map((item) => (
          <MenuItem key={Math.random()} item={item} />
        ))}
      </section>
      <section className="flex-align-cent flex-col ">
        <button className="sub-txt button-line-theme header-profile-menu-button">
          Sign out
        </button>
      </section>
      <div
        className="sub-txt flex-align-cent flex-just-cent secondary-txt"
        style={{ margin: "12px 0 16px" }}
      >
        <div>Privacy Policy ・ Terms of Use</div>
      </div>
    </div>
  );
};

export default Profile;
