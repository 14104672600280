import React from "react";
import { NavLink } from "react-router-dom";

import "./style.css";

const Tab = (props) => {
  return (
    <NavLink
      exact
      to={`/${props.root}${props.route}`}
      className="tabs-tab-wrap flex-align-bot txt bold "
      activeClassName="tabs-tab-wrap-selected"
    >
      <div className="global-trans">{props.name}</div>
      <div className="tabs-tab-select-lin rev-up" />
    </NavLink>
  );
};

export default Tab;
