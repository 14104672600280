import React from "react";

import "./style.css";

//icons
import DragHorizontal from "../../../media/svgs/DragHorizontal";

const Sec = (props) => {
  return (
    <div>
      <div className="Dash-sect-head flex-align-cent flex-just-out">
        <div>{props.title}</div>
        <button className="button-icon-back">
          <DragHorizontal />
        </button>
      </div>
      {props.children}
    </div>
  );
};

export default Sec;
