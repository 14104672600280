import React from "react";
import "./style.css";

import Collection from "./Collection";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: false,
    };
    this.resizeObserver = null;
    this.wrapperRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const resizeObserver = new ResizeObserver((entries) => {
      let oldOverflowStatus = this.state.isOverflowing;
      let wrapperHeight = this.wrapperRef.current
        ? this.wrapperRef.current.clientHeight
        : 0;
      let clientHeight = this.containerRef.current
        ? this.containerRef.current.clientHeight
        : 0;
      let currentOverflowStatus = wrapperHeight < clientHeight;

      if (currentOverflowStatus != oldOverflowStatus)
        this.setState({ isOverflowing: currentOverflowStatus });
    });

    resizeObserver.observe(this.containerRef.current);
  }

  render() {
    return (
      <div
        id="wrapper"
        className={`navigation-section-wrapper global-trans ${
          !this.props.min ? "" : "navigation-section-wrapper-min"
        } ${
          this.state.isOverflowing ? "navigation-section-wrapper-lines" : ""
        }`}
        ref={this.wrapperRef}
      >
        <div
          id="container"
          className="navigation-section-container"
          ref={this.containerRef}
        >
          {this.props.collections &&
            this.props.collections.map((collection) => {
              console.log("remapping navigation");
              return (
                <Collection
                  key={collection.title + "-collection"}
                  title={collection.title}
                  apps={collection.apps}
                  min={this.props.min}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default Navigation;
