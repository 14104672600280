import React from "react";

import "./style.css";

//COMPS

const Home = (props) => {
  return <div className="app-home-wrap flex-align-cent flex-col">Settings</div>;
};

export default Home;
