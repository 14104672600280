import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./style.css";

//COMPS
import Tabs from "../Tabs";
import Spinner from "../../../comps/Spinner";

const Composer = (props) => {
  //variables
  const { name, features } = props.app;

  //functions
  const buildRoute = (name) => {
    return name.replace(/\s/g, "-").toLowerCase();
  };
  const buildPath = (name) => {
    return name.replace(/\s/g, "");
  };
  const importFeature = (app, feature) => {
    return React.lazy(() =>
      import(`../../${buildPath(app)}/${buildPath(feature)}`).catch(() => ({
        default: () => (
          <div className="console-app-404-msg rev-up">
            Feature does not exist... yet
          </div>
        ),
      }))
    );
  };

  return (
    <div className="app-feature-wrap flex-align-cent flex-col">
      <Tabs display={features.length < 2} tabs={features} root={props.root} />
      {features.length ? (
        <Switch>
          {features.map((feature, i) => {
            console.log("remapping features");
            const route = i > 0 ? "/" + buildRoute(feature.name) : "";
            const Feature = importFeature(name, feature.name);
            return (
              <Route
                key={route + "-key"}
                path={`/${props.root}${route}`}
                exact
                render={() => (
                  <Suspense fallback={<Spinner />}>
                    <Feature
                      app={name}
                      feature={feature.name}
                      windows={feature.windows}
                    />
                  </Suspense>
                )}
              />
            );
          })}
        </Switch>
      ) : (
        <div className="console-app-404-msg rev-up">
          App does not exist...yet
        </div>
      )}
    </div>
  );
};

export default Composer;
