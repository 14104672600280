import React, { useState, Suspense } from "react";

import "../style.css";

import IconButton from "./IconButton";

const Navigation = React.memo((props) => {
  const buildRoute = (name) => {
    return name.replace(/\s/g, "-").toLowerCase();
  };

  const { active, appString, stringLength } = props;

  return (
    <div className="nav-section-min flex-align-cent flex-col">
      {props.apps.map((app) => {
        const route = buildRoute(app.name);
        const Icon = React.lazy(() =>
          import(
            `../../../../../media/svgs/console/${app.name.replace(/\s/g, "")}`
          )
        );
        return (
          <IconButton
            key={route + "-min"}
            route={route}
            Icon={Icon}
            name={app.name}
          />
        );
      })}
    </div>
  );
});

export default Navigation;
