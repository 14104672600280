import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//styling
import "./App.css";

//redux
import { Provider } from "react-redux";
import { store } from "./redux/store";

//components
import Header from "./comps/Header";
import Navigation from "./comps/Navigation";
import Composer from "./apps/comps/Composer";

//pages
import Home from "./pages/Home";
import Settings from "./pages/Settings";

function App() {
  const [sideNav, setSideNav] = useState(false);

  //check theme
  const currentTheme =
    JSON.parse(localStorage.getItem("theme")) === false ? false : true;
  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", "dark");
  }

  //Minimum of two windows for this to work
  let collections = [
    {
      title: "Find it",
      apps: [
        {
          name: "Flippability Search",
          devCycle: "Beta",
          features: [
            {
              name: "Search",
              windows: ["Search", "Filter", "Proprty"],
            },
            {
              name: "Scout",
              windows: ["Alerts", "Filter"],
            },
            {
              name: "History",
              windows: [],
            },
          ],
        },
        {
          name: "Trend Analysis",
          devCycle: "Beta",
          features: [],
        },
        {
          name: "Project Builder",
          devCycle: "Beta",
          features: [],
        },
        {
          name: "Watchlist",
          devCycle: "Beta",
          features: [],
        },
        {
          name: "Realtor Portal",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Financing Portal",
          devCycle: "Alpha",
          features: [],
        },
      ],
    },
    {
      title: "Flip it",
      apps: [
        {
          name: "To-do",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Budgeting",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Contractor Portal",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Supplier Portal",
          devCycle: "Alpha",
          features: [],
        },
      ],
    },
    {
      title: "Sell it",
      apps: [
        {
          name: "Auto Listing",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Financials",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Agent Portal",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Archive",
          devCycle: "Alpha",
          features: [],
        },
      ],
    },
    {
      title: "Rent it",
      apps: [
        {
          name: "Rent Planner",
          devCycle: "Alpha",
          features: [],
        },
        {
          name: "Landlord Portal",
          devCycle: "Alpha",
          features: [],
        },
      ],
    },
  ];

  const buildRoute = (appName) => {
    return appName.replace(/\s/g, "-").toLowerCase();
  };

  return (
    <Provider store={store}>
      <Router>
        <Navigation
          collections={collections}
          sideNav={sideNav}
          setNav={() => setSideNav(!sideNav)}
        />
        <div className="content-space flex-col ">
          <Header setNav={() => setSideNav(!sideNav)} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/settings" component={Settings} />
            {collections.map((collection, i) =>
              collection.apps.map((app, i) => {
                console.log("remapping Appliciton");
                let route = buildRoute(app.name);
                return (
                  <Route
                    key={route}
                    path={`/${route}`}
                    render={(props) => (
                      <Composer {...props} app={app} root={route} />
                    )}
                  />
                );
              })
            )}
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
