import React from "react";

import "./style.css";

const Switch = (props) => {
  return (
    <div className={`theme-switch-wrapper ${props.className}`}>
      <label className="theme-switch" htmlFor="checkbox">
        <input type="checkbox" id="checkbox" readOnly checked={props.checked} />
        <div className="slider round global-trans"></div>
      </label>
    </div>
  );
};

export default Switch;
