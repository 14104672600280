import React from "react";

const Svg = (props) => {
  return (
    <svg viewBox="0 0 24 24" className={`${props.className}`}>
      <path d="M12 2L2 12H4V21H20V12H22M12 5L18 11V19H6V11M11 10H13V13H16V15H13V18H11V15H8V13H11V10Z" />
    </svg>
  );
};

export default Svg;
