import React from "react";

import "./style.css";

//icon
import MenuDwn from "../../../../media/svgs/MenuDwn";
import Prop from "../../../../media/svgs/Prop";

const Tile = (props) => {
  let truncate = 16;
  return !props.property ? (
    <div className={`header-prop-tile flex-align-cent global-trans`}>
      <button className="button-nav" style={{ padding: `0 4px 0 0` }}>
        <Prop />
        <div>Active property</div>
      </button>
    </div>
  ) : (
    <div
      onClick={props.onClick}
      className={`header-prop-tile flex-align-cent global-trans ${
        !props.active ? null : "header-prop-tile-active"
      } ${!props.open ? null : "header-prop-tile-open"}`}
    >
      <img src={props.property.img} alt="flipit property" />
      <div
        className={`header-prop-tile-txt flex-col ${
          !props.open ? "desktop-only" : ""
        }`}
      >
        {props.active && !props.open ? (
          <div
            className="sub-txt disabled-txt rev-down"
            style={{ height: "18px", marginTop: "-2px" }}
          >
            Active Property:
          </div>
        ) : null}
        <div className="primary-txt flex-align-cent">
          {props.property.address && props.property.address.length > truncate
            ? `${props.property.address.substring(0, truncate)}...`
            : props.property.address}
          <div style={{ height: "24px", margin: "-2px 0 -2px 10px" }}>
            {!props.active ? null : <MenuDwn className="global-trans" />}
          </div>
        </div>
        {props.open ? (
          <div
            className="sub-txt secondary-txt rev-down"
            style={{ height: "16px" }}
          >
            {props.property.state}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Tile;
