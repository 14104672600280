import React from "react";
import ReactDOM from "react-dom";

const Profile = (props) => {
  return (
    <div
      onClick={props.item.func}
      className="flex-align-cent flex-just-out txt header-profile-menu-item global-trans"
    >
      <div>{props.item.txt}</div>
      <div>{props.item.media}</div>
    </div>
  );
};

export default Profile;
