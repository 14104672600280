import React from "react";

const Svg = (props) => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M18.35,6H3.05C2.58,6,2.2,6.45,2.2,7s0.38,1,0.85,1h15.3c0.47,0,0.85-0.45,0.85-1S18.82,6,18.35,6z" />
      <path d="M21.2,11h-18c-0.55,0-1,0.45-1,1s0.45,1,1,1h18c0.55,0,1-0.45,1-1S21.76,11,21.2,11z" />
      <path d="M13.2,16h-10c-0.5,0-1,0.45-1,1s0.5,1,1,1h10c0.5,0,1-0.45,1-1S13.7,16,13.2,16z" />
    </svg>
  );
};

export default Svg;

// <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />

//
// <svg style="width:24px;height:24px" viewBox="0 0 24 24">
//     <path fill="currentColor" d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z" />
// </svg>
//
