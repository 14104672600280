import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import uiReducer from "./reducers/uiReducer";
import userReducer from "./reducers/userReducer";
import actPropReducer from "./reducers/actPropReducer";
import proprtyReducer from "./reducers/proprtyReducer";

const initialState = {};
const middleware = [thunk];

const reducers = combineReducers({
  Ui: uiReducer,
  User: userReducer,
  Proprty: proprtyReducer,
  ActProp: actPropReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
export const store = createStore(reducers, initialState, enhancer);
