import React, { Suspense } from "react";
import { NavLink } from "react-router-dom";

import "../style.css";

//COMPS
import Spinner from "../../../../Spinner";

//icons
import Alpha from "../../../../../media/svgs/Alpha.js";
import Beta from "../../../../../media/svgs/Beta.js";

const Navigation = (props) => {
  const buildRoute = (name) => {
    return name.replace(/\s/g, "-").toLowerCase();
  };

  const { active, appString, stringLength } = props;

  return (
    <div>
      {props.apps && active ? (
        props.apps.map((app) => {
          console.log("remapping navigation items");
          const route = buildRoute(app.name);
          const Icon = React.lazy(() =>
            import(
              `../../../../../media/svgs/console/${app.name.replace(/\s/g, "")}`
            )
          );
          return (
            <NavLink
              to={`/${route}`}
              className="bold txt nav-section-butt flex-align-cent flex-just-out global-trans"
              activeClassName="nav-section-butt-active"
              key={route + "-max"}
            >
              <div className="flex-align-cent ">
                <Suspense fallback={<Spinner />}>
                  <Icon />
                </Suspense>
                {app.name}
              </div>
              {!app.devCycle ? null : app.devCycle === "Beta" ? (
                <Beta />
              ) : (
                <Alpha />
              )}
            </NavLink>
          );
        })
      ) : (
        <div className="secondary-txt sub-txt nav-sec-sub-txt">
          {appString.length > stringLength
            ? appString.substring(0, stringLength) + "..."
            : appString}
        </div>
      )}
    </div>
  );
};

export default Navigation;
