import React from "react";

const Svg = (props) => {
  return (
    <svg viewBox="0 0 24 24" className={`${props.className}`}>
      <path d="M7,10L12,15L17,10H7Z" />
    </svg>
  );
};

export default Svg;
