import React from "react";

import "./style.css";

const Navigation = (props) => {
  return (
    <a
      target="_blank"
      href="https://us-flipit.com/plans"
      style={{ width: "100%" }}
      className="nav-accnt-wrap global-trans"
    >
      {!props.min ? (
        <div>
          <div className="secondary-txt sub-txt  ">Account</div>
          <div className="flex-align-cent flex-just-out">
            <div className="bold selected-txt">Pro Flipper</div>
            <div className="bold primary-txt">Upgrade</div>
          </div>
        </div>
      ) : (
        <div className="nav-accnt-wrap-min">
          <div className="bold selected-txt" style={{ writingMode: "tb-rl" }}>
            Pro Flipper
          </div>
          <div
            className="secondary-txt sub-txt"
            style={{ writingMode: "tb-rl" }}
          >
            Account
          </div>
        </div>
      )}
    </a>
  );
};

export default Navigation;
