import React from "react";

import "./style.css";

const Sec = (props) => {
  return (
    <div className="dash-Tile-wrap">
      <div className="dash-Tile foreground-wrap-outline global-app-wrap">
        Help Item 1
      </div>
      <div className="dash-Tile foreground-wrap-outline global-app-wrap">
        Help Item 2
      </div>
      <div className="dash-Tile-lg foreground-wrap-outline global-app-wrap">
        Help Item 3
      </div>
    </div>
  );
};

export default Sec;
