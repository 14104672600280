import React, { useState, Suspense } from "react";
import { NavLink } from "react-router-dom";

import "./style.css";

//Components
import MaxView from "./MaxView";
import MinView from "./MinView";

//icons
import Alpha from "../../../../media/svgs/Alpha.js";
import Beta from "../../../../media/svgs/Beta.js";

const Navigation = (props) => {
  const savedState =
    JSON.parse(localStorage.getItem(`nav-${props.title}`)) === false
      ? false
      : true;

  const [active, setActive] = useState(savedState);

  let stringLength = 30;
  let appString = "";
  props.apps.map((app, i) => {
    let adition = i > 0 ? `, ${app.name}` : `${app.name}`;
    let place = appString + adition;
    return (appString = place);
  });

  function toggleNav() {
    localStorage.setItem(`nav-${props.title}`, !active);
    setActive(!active);
  }

  const buildRoute = (name) => {
    return name.replace(/\s/g, "-").toLowerCase();
  };

  return !props.min ? (
    <div
      className={`nav-section global-trans ${
        !active ? "" : "nav-section-act "
      }`}
      onClick={(e) => (!active ? toggleNav() : "")}
    >
      <div
        className="bold nav-section-title flex-align-cent global-trans "
        onClick={(e) => (active ? toggleNav() : "")}
      >
        <div>{props.title}</div>
        <svg className="rev-fade" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
          />
        </svg>
      </div>
      <MaxView
        apps={props.apps}
        active={active}
        appString={appString}
        stringLength={stringLength}
      />
    </div>
  ) : (
    <MinView
      apps={props.apps}
      active={active}
      appString={appString}
      stringLength={stringLength}
    />
  );
};

export default Navigation;
