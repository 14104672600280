import React, { useState } from "react";

import "./style.css";

//component
import ProfileMenu from "./ProfileMenu";

//media
import Placeholder from "../../../media/images/placeholder.jpg";

const Profile = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-profile-wrap flex-align-cent">
      <button className="button-txt-theme-lite header-profile-GTF-button">
        <div>Go to Forum</div>
      </button>
      <button className="button-icon-button">
        <svg viewBox="0 0 24 24">
          <path d="M16,17H7V10.5C7,8 9,6 11.5,6C14,6 16,8 16,10.5M18,16V10.5C18,7.43 15.86,4.86 13,4.18V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V4.18C7.13,4.86 5,7.43 5,10.5V16L3,18V19H20V18M11.5,22A2,2 0 0,0 13.5,20H9.5A2,2 0 0,0 11.5,22Z" />
        </svg>
      </button>
      <div
        className="header-profile-img global-trans"
        onClick={(e) => setOpen(!open)}
      >
        <img src={Placeholder} alt="Flipit Profile Pic" />
      </div>
      {!open ? "" : <ProfileMenu proPic={Placeholder} setOpen={setOpen} />}
    </div>
  );
};

export default Profile;
