import React from "react";

import "./style.css";

const Navigation = (props) => {
  return (
    <div
      style={{ width: "100%" }}
      className={`nav-collap-wrap  desktop-only ${
        !props.min ? "flex-just-rit" : "nav-collap-wrap-min flex-just-cent"
      }`}
    >
      <button
        className="button-icon global-trans"
        style={{ transform: !props.min ? "" : "rotate(180deg)" }}
        onClick={props.setMin}
      >
        <svg viewBox="0 0 24 24">
          <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
      </button>
    </div>
  );
};

export default Navigation;
