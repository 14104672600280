import React, { useState, Suspense } from "react";
import { NavLink } from "react-router-dom";

import "../../style.css";
import "../../../../../Tooltip/style.css";
import Spinner from "../../../../../Spinner";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayToolTip: false };
    this.iconRef = React.createRef();
  }
  render() {
    const { Icon, route, name } = this.props;
    const position = this.iconRef.current
      ? this.iconRef.current.getBoundingClientRect().top - 14
      : 0;

    return (
      <NavLink
        to={`/${route}`}
        className="nav-section-min-icon-wrap flex-just-cent global-trans"
        activeClassName="nav-section-butt-active"
        onMouseEnter={(e) => this.setState({ displayToolTip: true })}
        onMouseLeave={(e) => this.setState({ displayToolTip: false })}
      >
        <button ref={this.iconRef} className="button-icon">
          <Suspense fallback={<Spinner />}>
            <Icon />
          </Suspense>
        </button>
        <div
          className="tooltip-window sub-txt rev-rit"
          style={{
            display: !this.state.displayToolTip ? "none" : "block",
            top: position,
          }}
        >
          {name}
        </div>
      </NavLink>
    );
  }
}
export default Navigation;
