import React from "react";

const Svg = (props) => {
  return (
    <svg viewBox="0 0 24 24" className={`${props.className}`}>
      <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />{" "}
    </svg>
  );
};

export default Svg;
